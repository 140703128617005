import type React from 'react';

import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { TwBaseLayout } from '@ll-platform/frontend/components/layouts/TwBaseLayout/TwBaseLayout';
import { Button } from '@ll-platform/frontend/components/shadcn/ui/button';
import { NavigationBar } from '@ll-platform/frontend/components/tw/TwNavigationBar/TwNavigationBar';
import { Pages } from '@ll-platform/frontend/core/router/pages';
import { ProposalTitle } from '@ll-platform/frontend/creativeProposal/proposal/components/ProposalTitle';

export const TwBaseProposalLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();

  return (
    <TwBaseLayout
      fullscreen
      background="concepts-page-gradient"
      navigationBar={
        <NavigationBar>
          <div className="flex items-center">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => navigate(Pages.CreativeProposalDashboard)}
              className="px-4 text-foreground"
            >
              <ArrowLeft />
            </Button>

            <ProposalTitle />
          </div>
        </NavigationBar>
      }
    >
      {children}
    </TwBaseLayout>
  );
};
