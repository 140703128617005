import { Outlet, type RouteObject } from 'react-router-dom';

import { TwBaseProposalLayout } from '@ll-platform/frontend/components/layouts/TwBaseProposalLayout/TwBaseProposalLayout';
import {
  lazyLoad,
  withLoader,
} from '@ll-platform/frontend/core/router/helpers';
import { EnsureDarkTheme } from '@ll-platform/frontend/core/theme/GlobalThemeProvider';
import { IsInternalLoggedInGuard } from '@ll-platform/frontend/features/auth/guards/IsLoggedInGuard';
import { InternalDashboardLayout } from '@ll-platform/frontend/features/internalDashboard/components/layout/InternalDashboardLayout';

import { NewCreativeProposalFeatureFlagGuard } from './NewCreativeProposalFeatureFlagGuard';

const CreativeProposalsPage = withLoader(
  lazyLoad(() => import('./dashboard/pages/CreativeProposalsPage'))
    .CreativeProposalsPage,
);
const CreativeProposalController = withLoader(
  lazyLoad(() => import('./CreativeProposalController'))
    .CreativeProposalController,
);

export const creativeProposalRoutes: RouteObject[] = [
  {
    path: 'creative-proposal',
    element: (
      <NewCreativeProposalFeatureFlagGuard>
        <Outlet />
      </NewCreativeProposalFeatureFlagGuard>
    ),
    children: [
      {
        path: 'dashboard',
        element: (
          <IsInternalLoggedInGuard>
            <EnsureDarkTheme>
              <Outlet />
            </EnsureDarkTheme>
          </IsInternalLoggedInGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <InternalDashboardLayout>
                <CreativeProposalsPage />
              </InternalDashboardLayout>
            ),
          },
        ],
      },
      {
        path: ':id',
        element: (
          <IsInternalLoggedInGuard>
            <EnsureDarkTheme>
              <TwBaseProposalLayout>
                <CreativeProposalController />
              </TwBaseProposalLayout>
            </EnsureDarkTheme>
          </IsInternalLoggedInGuard>
        ),
      },
    ],
  },
];
