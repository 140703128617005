import {
  MutationCache,
  QueryCache,
  QueryClient,
  type keepPreviousData,
  type Mutation,
  type Query,
  type QueryKey,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { extractErrorMessage } from '@ll-platform/frontend/utils/helpers/errors';

interface MetaOptions {
  supressErrorToast?: boolean;
}

const queryRequestErrorHandler = (
  error: Error,
  query: Query<unknown, unknown, unknown, QueryKey>,
) => {
  console.error(error);
  const message = extractErrorMessage({
    error,
    fallbackMessage: 'Request failed',
  });

  if (!query.meta?.supressErrorToast) {
    toast.error(message);
  }
};

const mutationRequestErrorHandler = (
  error: unknown,
  _variables: unknown,
  _context: unknown,
  mutation: Mutation<unknown, unknown, unknown, unknown>,
  // eslint-disable-next-line max-params
) => {
  console.error(error);
  const message = extractErrorMessage({
    error,
    fallbackMessage: 'Request failed',
  });

  if (!mutation.options.meta?.supressErrorToast) {
    toast.error(message);
  }
};

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: queryRequestErrorHandler,
  }),
  mutationCache: new MutationCache({
    onError: mutationRequestErrorHandler,
  }),
});

declare module '@tanstack/react-query' {
  interface QueryMeta extends MetaOptions {}
  interface MutationMeta extends MetaOptions {}
}

export type UniversalQueryOptions = Pick<
  UseQueryOptions,
  'enabled' | 'staleTime' | 'meta'
> & { refetchInterval?: number; placeholderData?: typeof keepPreviousData };
