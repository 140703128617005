import { type QueryClient } from '@tanstack/react-query';

import { createMutationHook } from '@ll-platform/frontend/utils/factories/createMutationHook';

import { creativeProposalsService } from './CreativeProposalsService';
import { CreativeProposalsQueries } from './useCreativeProposalsQueries';

export const useCreateCreativeProposal = createMutationHook(
  creativeProposalsService.create.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useCloneCreativeProposal = createMutationHook(
  creativeProposalsService.cloneById.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useUpdateCreativeProposal = createMutationHook(
  creativeProposalsService.update.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useDeleteCreativeProposalById = createMutationHook(
  creativeProposalsService.deleteById.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useCreateProposalConcept = createMutationHook(
  creativeProposalsService.createConcept.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useDeleteConceptsByIds = createMutationHook(
  creativeProposalsService.deleteConceptById.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);
